import gql from 'graphql-tag';

export const UPLOAD_GARBAGE = gql`
    mutation UploadGarbageData($email: String!, $weight: Float!, $organic: Boolean!) {
        uploadGarbageData(garbageData: { email: $email, weight: $weight, organic: $organic }) {
            code
            success
            message
            garbage {
                email
                weight
                organic
            }
        }
    }
`;
