import React, { useState } from 'react';
import {
    Container,
    InputContainer,
    Label,
    ContentContainer,
    Button,
    Input,
    CheckBox,
    Title,
    SubTitle,
    Logo,
    CheckBoxContainer,
    CheckBoxLink,
    CheckBoxText,
    StatisticsLogo,
    StatisticsText,
} from './FormLoadGarbageElements';

import { UPLOAD_GARBAGE } from '../../mutations/uploadGarbageData';
import { GET_GARBAGE_STATISTICS } from '../../queries/getGarbageData';
import { formText } from '../../assets/data';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';

const FormLoadGarbage = () => {
    const [weight, setWeight] = useState('');
    const [email, setEmail] = useState('');
    const [organic, setOrganic] = useState(true);
    const [hasAccepted, setHasAccepted] = useState(false);

    const [uploadGrarbage] = useMutation(UPLOAD_GARBAGE, {
        refetchQueries: [
            {
                query: GET_GARBAGE_STATISTICS,
                variables: { email },
            },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
            navigate('/graphs/', { state: { email } });
        },
    });

    const onClickUpdateGarbage = async () => {
        if (checkAvaibaleStatistics) {
            await uploadGrarbage({
                variables: { email: email, organic: organic, weight: weight },
            });
        }
    };

    const onClickStatistics = () => {
        navigate('/graphs/', { state: { email } });
    };

    const checkAvaibale = () => {
        return !(email !== '' && weight !== '' && hasAccepted);
    };

    const checkAvaibaleStatistics = () => {
        return !(email !== '');
    };

    return (
        <Container>
            <Logo to="/" />
            <ContentContainer>
                <Title>{formText.title}</Title>
                <SubTitle>
                    Bienvenido/a vivir la experiencia de valorizar tus residuos domiciliarios y
                    contribuir al cuidado del ambiente.
                    <br />
                    <br />
                    “Todo parece Imposible hasta que se Hace” Nelson Mandela
                    <br />
                    <br />
                    El planeta necesita que lo cuidemos, vos podes ser parte de su recomposición y
                    el momento de empezar a actuar es hoy.
                    <br />
                    <br />
                    Paso 1. <br />
                    Crea tu usuario ingresando tu email y registra el peso de tus residuos durante
                    30 días de la forma que lo realizas habitualmente, sin realizar ningún cambio a
                    esta actividad.
                </SubTitle>
                <Label>Email</Label>
                <InputContainer>
                    <Input
                        type="email"
                        value={email}
                        placeholder="Ingrese su email"
                        onChange={(element) => setEmail(element.target.value.toString())}
                    />
                    <StatisticsLogo disabled={checkAvaibaleStatistics()} />
                    <StatisticsText
                        onClick={() => onClickStatistics()}
                        disabled={checkAvaibaleStatistics()}
                    >
                        Estadisticas
                    </StatisticsText>
                </InputContainer>
                <CheckBoxContainer>
                    <CheckBox
                        type="checkbox"
                        onChange={(element) => setHasAccepted(element.target.checked)}
                    />
                    <CheckBoxText>
                        He leído y acepto los {''}
                        <CheckBoxLink target="_blank" href="/termsAndConditions">
                            términos y condiciones
                        </CheckBoxLink>
                    </CheckBoxText>
                </CheckBoxContainer>
                <Label>¿Cuánto pesan tus residuos hoy?</Label>
                <InputContainer>
                    <Input
                        onpaste="return false;"
                        ondrop="return false;"
                        autocomplete="off"
                        placeholder="Expresado en kilogramos"
                        type="number"
                        value={weight}
                        onChange={(element) =>
                            setWeight(parseFloat(element.target.value.toString()))
                        }
                    />
                </InputContainer>
                <CheckBoxContainer>
                    <CheckBox
                        type="checkbox"
                        defaultChecked={true}
                        onChange={(element) => setOrganic(element.target.checked)}
                    />
                    <CheckBoxText>Mis residuos contienen materiales Orgánicos</CheckBoxText>
                </CheckBoxContainer>

                <Button
                    disabled={checkAvaibale()}
                    onClick={() => {
                        onClickUpdateGarbage();
                    }}
                >
                    Cargar Residuos
                </Button>
            </ContentContainer>
        </Container>
    );
};

export default FormLoadGarbage;
